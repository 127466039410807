<template>
    <div class="header">
        <img src="../../assets/img/text_logo2.png" style="height:40px;margin-top: 10px;" alt=""/>
        <section style="display:flex">
            <el-menu
                    mode="horizontal"
                    class="el-menu-demo"
                    background-color="#fff"
                    text-color="#909399"
                    active-text-color="#0678BE"
                    :default-active="currentIndex"
                    @select="showDetails"
            >
                <el-menu-item
                        v-for="(item, index) in category"
                        :key="item.id"
                        :index="item.id"
                >
                    <span>{{ item.name }}</span>
                </el-menu-item>
            </el-menu>
            <div v-if="user" class="login-box user-name">
                <el-dropdown>
                    <a href="javascript:void(0)" style="color:#0678BE">
                        {{ user }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </a>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="hglogout"
                        >退出登录
                        </el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div
                    v-else
                    class="login-box"
                    style="cursor:pointer"
                    @click="(dialogVisible = true), (title = '登录'), (forgetPwd = false)"
            >
                {{ "登录 注册" }}
            </div>
        </section>
        <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                width="540px"
                append-to-body
                :before-close="handleClose"
                :close-on-click-modal="false"
        >
            <transition mode="out-in">
                <el-form
                        :key="1"
                        class="login-container"
                        style="padding:0 50px;height:160px"
                        v-if="forgetPwd"
                >
                    <el-form-item style="margin:40px 0 58px 0">
                        <el-input
                                type="text"
                                placeholder="请输入要重置密码的帐号"
                                v-model.trim="account"
                                autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                type="primary"
                                @click="sendEmail"
                                style="width: 100%;"
                                :loading="btnLoading"
                        >确定
                        </el-button
                        >
                    </el-form-item>
                    <el-row style="width:100%;margin-top: -10px;display:flex;">
                        <el-link
                                type="primary"
                                @click="(forgetPwd = false), (title = '登录')"
                        >返回登录
                        </el-link
                        >
                        <div style="flex:1"></div>
                    </el-row>
                </el-form>
                <el-form
                        class="login-container"
                        style="padding:0 50px;height:200px"
                        v-else
                >
                    <el-form-item>
                        <el-input
                                type="text"
                                placeholder="用户账号"
                                v-model.trim="username"
                                autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                                type="password"
                                placeholder="用户密码"
                                v-model.trim="password"
                                autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doLogin" style="width: 100%;"
                        >登录
                        </el-button
                        >
                    </el-form-item>
                    <el-row
                            style="width:100%;margin-top: -10px;display:flex;justify-content:space-between"
                    >
                        <el-link type="primary" @click="toRegister">用户注册</el-link>
                        <div style="flex:1"></div>
                        <section>
                            <el-link
                                    type="primary"
                                    @click="forgetClick('忘记密码')"
                                    style="margin-right:5px"
                            >忘记密码
                            </el-link
                            >
                            <el-link type="primary" @click="forgetClick('修改密码')"
                            >修改密码
                            </el-link
                            >
                        </section>
                    </el-row>
                </el-form>
            </transition>

            <el-dialog
                    width="540px"
                    title="注册"
                    :visible.sync="innerVisible"
                    append-to-body
                    :before-close="handleCloseInner"
                    :close-on-click-modal="false"
            >
                <el-form
                        :model="ruleForm"
                        :rules="rules"
                        ref="ruleForm"
                        label-width="120px"
                >
                    <el-form-item label="账号" prop="user">
                        <el-input
                                v-model.trim="ruleForm.user"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input
                                v-model.trim="ruleForm.password"
                                type="password"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="密码验证" prop="password2">
                        <el-input
                                type="password"
                                v-model.trim="ruleForm.password2"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="企业名字" prop="enterpriseName">
                        <el-input
                                v-model.trim="ruleForm.enterpriseName"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="企业注册地址" prop="enterpriseAddress">
                        <el-input
                                v-model.trim="ruleForm.enterpriseAddress"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="企业联系人" prop="name">
                        <el-input
                                v-model.trim="ruleForm.name"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="phone">
                        <el-input
                                v-model.trim="ruleForm.phone"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="eMail">
                        <el-input
                                v-model.trim="ruleForm.eMail"
                                autocomplete="off"
                                size="small"
                                style="width:300px"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="registerConfirm">注 册</el-button>
        </span>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script>
    import {get, post} from "../../utils/request";

    export default {
        inject: ["reload"],
        data() {
            const checkPwd2 = (rule, value, callback) => {
                if (value.trim().length == 0) {
                    callback(new Error("请确认密码不能为空"));
                } else if (value != this.ruleForm.password) {
                    callback(new Error("2次密码不一致"));
                } else {
                    callback();
                }
            };
            //验证邮箱的规则
            const checkEmail = (rule, value, cb) => {
                //验证邮箱的正则表达式
                // const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
                const regEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                if (regEmail.test(value)) {
                    //合法的邮箱
                    return cb();
                }
                //邮箱不合法
                cb(new Error("该邮箱用于修改密码和找回密码，请输入正确的邮箱"));
            };
            return {
                title: "登录",
                forgetPwd: false,
                dialogVisible: false,
                username: "",
                password: "",
                user: "",
                account: "",
                btnLoading: false,
                // 所有一级栏目
                // category: [
                //     {name: "行业新闻", id: "news", index: "1"},
                //     {name: "行业标准", id: "standard", index: "2"},
                //     {name: "行业服务", id: "module", index: "3"},
                //     // { name: "智慧园区", id: "wise" },
                //     {name: "行业专家库", id: "experts", index: "4"},
                //     {name: "行业服务商资源池", id: "service", index: "5"},
                // ],
				category: [
				    {name: "新闻资讯", id: "news", index: "1"},
				    {name: "行业案例", id: "standard", index: "2"},
				    {name: "平台优势", id: "module", index: "3"},
				    // { name: "智慧园区", id: "wise" },
				    {name: "客户池", id: "experts", index: "4"},
				    {name: "平台功能", id: "service", index: "5"},
				],
                currentIndex: "1",
                innerVisible: false,
                ruleForm: {
                    user: "",
                    password: "",
                    password2: "",
                    enterpriseName: "",
                    enterpriseAddress: "",
                    name: "",
                    phone: "",
                    eMail: "",
                },
                rules: {
                    user: [{required: true, message: "请输入账号", trigger: "blur"}],
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {
                            pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/,
                            message: "密码为8位及8位以上（要求存在字母，数字，特殊字符）",
                        },
                    ],
                    password2: [
                        {
                            required: true,
                            validator: checkPwd2,
                            message: "请确认密码一致",
                            trigger: "blur",
                        },
                    ],
                    enterpriseName: [
                        {required: true, message: "请输入企业名字", trigger: "blur"},
                    ],
                    enterpriseAddress: [
                        {required: true, message: "请输入企业注册地址", trigger: "blur"},
                    ],
                    name: [
                        {required: true, message: "请输入企业联系人", trigger: "blur"},
                    ],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                // if (/^1[34578]\d{9}$/.test(value) == false) {
                                if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value) == false) {
                                    callback(new Error("请输入正确的手机号"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    eMail: [
                        {required: true, message: "该邮箱用于修改密码和找回密码，请输入正确的邮箱", trigger: "blur"},
                        {validator: checkEmail, trigger: "blur"},
                    ],
                },
            };
        },
        created() {
            this.$nextTick(function () {
                window.addEventListener("scroll", this.onScroll);
            });
            this.user = localStorage.user;
        },
        computed: {},
        methods: {
            forgetClick(title) {
                this.title = title;
                this.forgetPwd = true;
                this.account = "";
                this.username = "";
                this.password = "";
            },

            sendEmail() {
                if (this.account == "" || this.account == null) {
                    this.$message({
                        message: "请输入正确的账号",
                        type: "warning",
                    });
                    return;
                }
                this.btnLoading = true;
                get("/api/sendEamil/send?user=" + this.account).then((res) => {
                    this.btnLoading = false;
                    if (res.data.code == 200) {
                        this.$message({
                            message: "邮件发送成功,请前往邮箱确认。",
                            type: "success",
                        });
                        (this.forgetPwd = false), (this.title = "登录");
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "warning",
                        });
                    }
                });
            },
            hglogout() {
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                this.user = null;
                this.$message({
                    message: "退出登录成功",
                    type: "success",
                });
            },
            doLogin() {
                if (!this.username) {
                    this.$message({
                        message: "请输入用户账号",
                        type: "warning",
                    });
                    return;
                }
                if (!this.password) {
                    this.$message({
                        message: "请输入密码",
                        type: "warning",
                    });
                    return;
                }
                get(
                    "/api/login?user=" + this.username + "&password=" + this.password
                ).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: "登录成功",
                            type: "success",
                        });
                        let tem = res.data.data;
                        this.user = tem.user;
                        localStorage.token = tem.token;
                        localStorage.user = tem.user;
                        this.handleClose();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "warning",
                        });
                    }
                });
            },
            toRegister() {
                this.innerVisible = true;
            },
            handleClose() {
                this.username = "";
                this.password = "";
                this.dialogVisible = false;
                this.forgetPwd = false;
            },
            handleCloseInner() {
                this.ruleForm = {
                    user: "",
                    password: "",
                    password2: "",
                    enterpriseName: "",
                    enterpriseAddress: "",
                    name: "",
                    phone: "",
                    eMail: "",
                };
                this.innerVisible = false;
            },
            registerConfirm() {
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        post("/api/logins", this.ruleForm).then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    message: "注册成功",
                                    type: "success",
                                });
                                this.handleCloseInner();
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "warning",
                                });
                            }
                        });
                    } else {
                        this.$message({
                            message: "表单验证失败",
                            type: "warning",
                        });
                        return false;
                    }
                });
            },

            showDetails(id) {
                if (id === "wise") {
                    if (!this.user) {
                        this.forgetPwd = false;
                        this.dialogVisible = true;
                        setTimeout(() => {
                            this.$message({
                                message: "请先登录",
                                type: "warning",
                            });
                        }, 100);
                        return;
                    }

                    get("/api/checkToken").then((res) => {
                        if (res.data.code != 200) {
                            localStorage.removeItem("token");
                            localStorage.removeItem("user");
                            this.user = null;
                            this.$refs.head.dialogVisible = true;
                            setTimeout(() => {
                                this.$message({
                                    message: "登录过期，请重新登录",
                                    type: "warning",
                                });
                            }, 100);
                            return;
                        } else {
                            localStorage.questionnaireType = 1;
                            const newHref = this.$router.resolve({name: "questionnaire"});
                            window.open(newHref.href, "_blank");
                            return;
                        }
                    });
                }
                var el = document.getElementById(id);

                this.$nextTick(function () {
                    window.scrollTo({behavior: "smooth", top: el && el.offsetTop - 60});
                });
            },
            // handleSelect(key, keyPath) {
            //   console.log(key, keyPath);
            // },
            changeIndex(index) {
                this.currentIndex = index;
            },

        },
    };
</script>
<style lang="less" scoped>
    .v-enter,
    .v-leave-to {
        opacity: 0;
    }

    .v-enter-acitve,
    .v-leave-active {
        transition: opacity 0.4s;
    }

    .on {
        // color: #ffcf46;
        background: #0678be;
    }

    .header {
        width: calc(100% - 70px);
        background: #fff;
        position: fixed;
        z-index: 1000;
        display: flex;
        justify-content: space-between;
        padding: 0 50px 0 50px;

        .h_top {
            width: 100%;
            // height: 40px;
            background: #0678be;
        }

        .h_logo {
            width: 1190px;
            height: 100px;
            margin: 0 auto;

            .left {
                float: left;

                img {
                    margin-top: 10px;
                }
            }

            .right {
                float: right;
                width: 275px;
                margin-top: 30px;

                /deep/ .el-input .el-input__inner {
                    border-radius: 25px;
                    height: 35px;
                    font-size: 13px;
                }

                /deep/ .el-input .el-input__icon {
                    width: 30px;
                    line-height: 36px;
                    font-size: 17px;
                }

                /deep/ .el-input--prefix .el-input__inner {
                    padding-left: 35px;
                }
            }
        }

        .el-menu.el-menu--horizontal {
            border-top: solid 1px #e6e6e6;
            // height: 50px;
            border-bottom: none;
            z-index: 9;
            // box-shadow: 0 3px 3px #c0c0c0;
        }

        .el-menu-demo .nav_item {
            width: 1220px;
            margin: 0 auto;
        }

        .el-menu-demo {
            display: flex;
            justify-content: flex-end;

            .el-menu-item.is-active {
                color: #0678be;
                // background: #680034;
            }

            .nav_item > li {
                padding: 0;
                width: 108px;
                height: 50px;
                line-height: 50px;
                float: left;
                text-align: center;
            }

            .nav_item > li:last-child {
                width: 125px;
            }

            .nav_item > li:hover {
                // background: #680034;
                color: #0678be;
            }

            .nav_item > li:hover ul {
                display: block;
            }

            .second_menu {
                position: absolute;
                top: 50px;
                width: 100%;
                left: 0;
                display: none;
                background-color: #fff;

                li {
                    border-bottom: 1px solid #b5b5b5;
                }

                li:last-child {
                    border-bottom: none;
                }

                li:hover > a {
                    color: #0678be;
                }
            }
        }
    }

    .login-box {
        height: 60px;
        display: flex;
        align-items: center;
        margin: 0 50px;
    }
</style>
