<template>
  <div>
    <!-- 轮播图 -->
    <div class="banner">
      <div class="banner-text" style="width: 100%;">
        <div>云表工业互联网平台</div>
        <div class="banner-block"></div>
        <div></div>
      </div>
      <el-carousel height="100vh" arrow="never" ref="cardShow">
        <el-carousel-item v-for="(item, index) in carousel" :key="index">
<!--          <img :src="item" alt="" />-->
          <img :src="item.image" alt="" @click="jumpPage(item.jumpUrl)" style="cursor: pointer"/>
          <div class="bottom_bg">
            <p class="title"></p>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="arrow">
        <div class="rightArrow" @click="arrowClick('right')"></div>
        <div class="leftArrow" @click="arrowClick('left')"></div>
      </div>
    </div>
    <!-- 主体部分 -->
    <div class="mainOne">
      <div class="wrap"></div>
    </div>
  </div>
</template>

<script>
import { get } from "../../utils/request";
export default {
  data() {
    return {
      carousel: [],
    };
  },
  mounted() {
    this.getBanner();
  },

  methods: {
    // 查询视频专区前5个
    getBanner() {
      get("/api/getBanner").then((res) => {
        if (res.status == 200) {
          this.carousel = res.data.data.image;
        }
      });
    },
    //跳转页面
    jumpPage(jumpUrl){
      if (jumpUrl != null && jumpUrl != '' && jumpUrl != undefined){
        window.open(jumpUrl,"_blank")
      }
    },
    // 自定义箭头
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 轮播图
.banner {
  // height: 460px;
  position: relative;
  .el-carousel__item {
    width: 100%;
    background-color: #ccc;
    img {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  .bottom_bg {
    left: 0;
    height: 58px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    font-size: 16px;
    line-height: 58px;
    background: rgba(0, 0, 0, 0.6);
    .title {
      width: 1190px;
      margin: 0 auto;
    }
  }
  /deep/.el-carousel__indicators {
    z-index: 999;
  }
  .arrow {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 95%;
    z-index: 99;
    .leftArrow {
      float: right;
      display: inline-block;
      width: 42px;
      height: 42px;
      margin-right: 15px;
      background: url("../../assets/yxtx/bg-left.png");
    }
    .leftArrow:hover {
      background: url("../../assets/yxtx/left.png");
      cursor: pointer;
    }
    .rightArrow {
      float: right;
      display: inline-block;
      width: 42px;
      height: 42px;
      background: url("../../assets/yxtx/bg-right.png");
    }
    .rightArrow:hover {
      background: url("../../assets/yxtx/right.png");
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1200px) {
    .el-carousel__item {
      width: 1200px;
      background-color: #ccc;
    }
    .arrow {
      width: 1140px;
      position: absolute;
      bottom: 6px;
      left: 0;
    }
    .leftArrow {
      float: right;
    }
    .rightArrow {
      float: right;
    }
  }
}
.banner-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 1104px;
  font-size: 32px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  line-height: 56px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.banner-block {
  width: 90px;
  height: 12px;
  background: #ffc52f;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
</style>
