<template>
  <div class="footer">
    <div class="footer_content">
      <div class="us_box">
        <div class="about_us footer-content-column" style="max-width: 500px">
          <h2 class="h_mg">咨询联系</h2>
          <ul class="about_list" style="text-align: left">
            <li>
              <span>电话：{{footInfo.phone}}</span>
            </li>
            <li>
              <span>邮箱：{{footInfo.email}}</span>
            </li>
            <li>
              <span>地址：{{footInfo.address}}</span>
            </li>
          </ul>
        </div>
        <!--<div class="contact_us footer-content-column">
          <h2 class="h_mg">联系我们</h2>
          <ul class="contact_list">
            <li>
              <a href="javascript:;" style="color:#F2F2F2;"
              >媒体问询</a
              >
            </li>
            <li>
              <a href="javascript:;" style="color:#F2F2F2;"
              >友情链接</a
              >
            </li>
            <li>
              <a href="javascript:;" style="color:#F2F2F2;"
              >技术支持</a
              >
            </li>
          </ul>
        </div>-->
      </div>
      <div class="media_box">
        <div class="h_mg" style="width:100%;text-align:center">
          <img :src="footInfo.imgUrl" alt="" style="width: 150px"/>
<!--          <img src="../../assets/img/erweima.png" alt="" style="width: 150px"/>-->
        </div>
        <!--<div class="media_list">
          <img src="../../assets/img/media_1.png" alt="" />
          <img src="../../assets/img/media_2.png" alt="" />
          <img src="../../assets/img/media_3.png" alt="" />
        </div>-->
      </div>
    </div>
    <div class="footer_baxx" @click="jumpBaxx()">
      <span>
        <span style="width: 10px;display: inline-block"></span>
        {{footInfo.copyRight}}
        <span style="width: 10px;display: inline-block"></span>
        {{footInfo.companyName}}
        <span style="width: 50px;display: inline-block"></span>
        {{footInfo.recordInfo}}
      </span>
    </div>
    <!--<div class="foot_title">江苏省化工园区信息化发展联盟</div>
    <div class="footer_content">
      <div class="us_box">
        <div class="about_us footer-content-column">
          <h2 class="h_mg">关于我们</h2>
          <ul class="about_list">
            <li>
              <a
                href="javascript:;"
                aria-current="page"
                class="router-link-exact-active router-link-active"
                style="color:#F2F2F2;"
                >联盟发展</a
              >
            </li>
          </ul>
        </div>
        <div class="contact_us footer-content-column">
          <h2 class="h_mg">联系我们</h2>
          <ul class="contact_list">
            <li>
              <a href="javascript:;" style="color:#F2F2F2;"
                >媒体问询</a
              >
            </li>
            <li>
              <a href="javascript:;" style="color:#F2F2F2;"
                >友情链接</a
              >
            </li>
            <li>
              <a href="javascript:;" style="color:#F2F2F2;"
                >技术支持</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="media_box">
        <div class="h_mg" style="width:100%;text-align:center">
          实时动态，扫码关注我们
        </div>
        <div class="media_list">
          <img src="../../assets/img/media_1.png" alt="" />
          <img src="../../assets/img/media_2.png" alt="" />
          <img src="../../assets/img/media_3.png" alt="" />
        </div>
      </div>
    </div>-->
    <!-- <div class="foot_end">
      © 2022-2023 江苏省化工园区信息化 发展联盟X公网安备 xxxxxxxxxxxxxx号 I
      ICP备xxxxxxxx号-1
    </div> -->
  </div>
</template>

<script>
import { get } from "../../utils/request";
export default {
  data() {
    return {
      ourText: "",
      footInfo: {},
    };
  },

  created() {
    this.getFootInfo();
  },
  mounted() {
    // this.getBannerOurText();
  },
  methods: {
    getBannerOurText() {
      get("/api/getBannerOurText").then((res) => {
        if (res.status == 200) {
          this.ourText = res.data.data.outText;
        }
      });
    },
    //获取底部备案信息
    getFootInfo() {
      get("/api/getHomeFootInfo").then((res) => {
        if (res.status == 200) {
          this.footInfo = res.data.data;
          console.log("getHomeFootInfo:");
          console.log(res);
        }
      });
    },

    jumpBaxx() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  clear: both;
  width: 100%;
  background: #282020;
  min-height: 200px;
  margin-top: 40px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  .fl {
    float: left;
  }
  .footer_content {
    max-width: 1440px;
    color: #fff;
    margin: 0 auto;
    padding: 39px 0 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .footer_baxx {
    cursor: pointer;
    text-align: center;
    max-width: 1440px;
    color: #fff;
    margin: 0 auto;
    padding: 39px 0 30px;
  }
  .us_box {
    width: 500px;
    display: flex;
    justify-content: space-between;
  }
  .about_us,
  .contact_us {
    max-width: 202px;
  }
  .footer-content-column .about_list,
  .footer-content-column .contact_list {
    text-align: center;
    font-size: 14px;
    line-height: 30px;
  }
  .about_us {
    margin-left: 120px;
  }
  .contact_us {
    margin-left: 120px;
  }
  .about_us,
  .contact_us {
    max-width: 202px;
  }
  .footer-content-column .contact_list {
    text-align: center;
    font-size: 14px;
    line-height: 30px;
  }

  .focus_us {
    text-align: left;
    padding-left: 73px;
  }
}
.h_mg {
  margin: 60px 0 33px 0;
}
.foot_title {
  width: 100%;
  text-align: center;
  height: 29px;
  font-size: 24px;
  font-family: ShiShangZhongHeiJianTi-Regular, ShiShangZhongHeiJianTi;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  padding-top: 45px;
}
.foot_end {
  width: 100%;
  text-align: center;
  height: 18px;
  font-size: 12px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  padding-bottom: 20px;
}
.media_box {
  width: 280px;
  .media_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
}
</style>
